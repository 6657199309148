/* istanbul ignore file */
import React, { FC } from 'react'
import { graphql } from 'gatsby'
import {
  makeStyles,
  Theme,
  Container,
  Link,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  withStyles,
  createStyles,
  Breadcrumbs,
  Typography,
  Grid,
} from '@material-ui/core'
import Layout from 'layout'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'
import HomeIcon from '@material-ui/icons/Home'
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt'
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow'
import BlurOnIcon from '@material-ui/icons/BlurOn'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Img, { FluidObject } from 'gatsby-image'
import Card from 'components/creative-tim/Card/Card.js'

interface BlogProps {
  data: {
    mdx: {
      body: string
      frontmatter: {
        date: string
        title: string
        description: string
        image: {
          childImageSharp: {
            fluid: FluidObject
          }
        }
        imageText: string
      }
    }
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  content: {
    padding: theme.spacing(2, 0, 2),
  },
}))

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell)

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow)

const StyledTable = withStyles(() =>
  createStyles({
    root: {
      width: '60%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  })
)(Table)

const Template: FC<BlogProps> = ({
  data, // this prop will be injected by the GraphQL query below.
}) => {
  const classes = useStyles()

  const { mdx } = data // data.markdownRemark holds your post data
  const { frontmatter, body } = mdx
  return (
    <Layout>
      <GatsbySeo
        title={frontmatter.title}
        description={frontmatter.description}
        openGraph={{
          title: frontmatter.title,
          description: frontmatter.description,
        }}
      />
      <div className={classes.content}>
        <Container maxWidth="lg">
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<DoubleArrowIcon fontSize="small" />}
          >
            <Link href="/" className={classes.link}>
              <HomeIcon className={classes.icon} />
              Home
            </Link>
            <Link href="/blog" className={classes.link}>
              <ViewQuiltIcon className={classes.icon} />
              Blog
            </Link>
            <Typography color="textPrimary" className={classes.link}>
              <BlurOnIcon className={classes.icon} />
              {frontmatter.title}
            </Typography>
          </Breadcrumbs>
          <Grid container>
            <Grid item md>
              <h1>{frontmatter.title}</h1>
              <p>{frontmatter.date}</p>
            </Grid>
            <Grid item md xs={12}>
              <Card>
                <Img
                  fluid={frontmatter.image.childImageSharp.fluid}
                  alt={frontmatter.imageText}
                />
              </Card>
            </Grid>
            <Grid item md />
          </Grid>
          <MDXProvider
            components={{
              a: Link,
              // eslint-disable-next-line react/display-name
              table: prop => <StyledTable {...prop} size="small" />,
              tr: StyledTableRow,
              th: StyledTableCell,
              td: StyledTableCell,
              thead: TableHead,
              tbody: TableBody,
            }}
          >
            <MDXRenderer className="blog-post-content">{body}</MDXRenderer>
          </MDXProvider>
        </Container>
      </div>
    </Layout>
  )
}

export default Template

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(slug: { eq: $slug }) {
      body
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        image: mainImage {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        imageText
      }
    }
  }
`
